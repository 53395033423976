import React, { useState } from 'react';
import { Flex, Text, Button, Collapse } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

interface HeadedInfoTextProps {
    headline: string;
    children: React.ReactNode;
}

const HeadedInfoText: React.FC<HeadedInfoTextProps> = ({ headline, children }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => setIsExpanded(!isExpanded);

    return (
        <Flex direction="column" alignItems="start" textAlign={"center"} pb="4">
            <Flex justifyContent="center" width="100%">
                <Text mb="2">{headline}</Text>
                <Button pb="2" onClick={toggleExpansion} size="sm" variant="ghost" rightIcon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}>
                    {isExpanded ? 'Less' : 'More'}
                </Button>
            </Flex>
            <Collapse in={isExpanded} animateOpacity>
                {children}
            </Collapse>
        </Flex>
    );
};

export default HeadedInfoText;

