import { Box, Button, Card, Flex, Heading, Text, useToast } from '@chakra-ui/react';
import { erc20Abi, keccak256 } from 'viem';
import { useAccount, useReadContract, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import { DgenTokenAddress, DidKeyRegistryAbi, DidKeyRegistryAddress } from '../contracts';
import GetToken from './GetToken';

const KEY_USAGE_AUTH = keccak256(Buffer.from("auth"));

const REQUIRED_DGEN_BALANCE = BigInt(100);

interface RegisterDidCardProps {
    onSuccess: () => void;
}

const RegisterDidCard = ({ onSuccess }: RegisterDidCardProps) => {
    const { address } = useAccount();
    const toast = useToast();

    const { writeContract: registerDid, isPending: isRegistering, data: registerDidHash, isError: isRegisteringError, error: registerDidError } = useWriteContract();
    const { isLoading: isConfirmingRegistering, isSuccess: isSuccessRegistering } = useWaitForTransactionReceipt({ hash: registerDidHash });

    const handleRegisterDid = async () => {
        if (!address || isRegistering) {
            return;
        }
        registerDid({
            account: address,
            address: DidKeyRegistryAddress,
            abi: DidKeyRegistryAbi,
            functionName: 'registerDid',
            args: [[{ id: 'default', keyType: 0, keyUsages: [KEY_USAGE_AUTH], publicKey: address, sudo: false }]],
        });
    };

    if (isSuccessRegistering) {
        toast({
            title: 'Registration Successful',
            description: "Your DID has been successfully registered.",
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
        onSuccess();
    }

    if (isConfirmingRegistering) {
        toast({
            title: 'Confirming registration',
            description: "Please confirm the registration in your wallet.",
            status: 'info',
            duration: 5000,
            isClosable: true,
        });
    }

    if (isRegisteringError) {
        toast({
            title: 'Registration Failed',
            description: registerDidError instanceof Error ? registerDidError.message : "An unknown error occurred",
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    }

    return (
        <Card p="6" m="6" boxShadow="lg">
            <Box mb="6" textAlign="center">
                <Heading color="teal.300" fontSize="3xl" mb="4">Enhance Your dgenDID with On-Chain Registration! 🚀</Heading>
                <Text color="gray.400" fontSize="xl">
                    Hello, Degens! 🌟 You already possess a functional dgenDID. Now, take the next step towards full digital sovereignty by registering it on-chain. <br />
                    On-chain registration isn't just about ownership; it's about unlocking advanced features like key rotation and capability delegation, enhancing your security and flexibility in the DeFi universe. <br />
                    Are you ready to elevate your digital identity? Let's strengthen your presence in the blockchain world together and explore the vast potential of enhanced DID features! 🌊
                </Text>
            </Box>
            <Flex justifyContent="center">
                <Button width="50%" colorScheme='teal' onClick={handleRegisterDid} isLoading={isRegistering || isConfirmingRegistering}>Register DID</Button>
            </Flex>
        </Card>
    );
};

export default RegisterDidCard;
