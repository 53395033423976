import React from 'react';
import { Box, Text, VStack, useToast, Card, Heading, Flex, Table, Tbody, Td, Th, Thead, Tr, List, ListIcon, ListItem } from '@chakra-ui/react';
import AddLinkedAccountButton from './AddLinkedAccountButton';
import RemoveLinkedAccountButton from './RemoveLinkedAccountButton';
import { LinkedAccount } from '@/hooks/useDidLinkedAccounts';
import LongString from './LongString';
import InfoText from './InfoText';
import { MdCheckCircle } from 'react-icons/md';
import HeadedInfoText from './HeadedInfoText';


interface LinkedAccountsProps {
    did: string;
    hasWriteAccess?: boolean;
    linkedAccounts: readonly LinkedAccount[] | undefined;
    isLoading: boolean;
    error: Error | null;
    onAddLinkedAccount: () => void;
    onRemoveLinkedAccount: () => void;
}

const LinkedAccounts = ({did, hasWriteAccess, linkedAccounts, isLoading, error, onAddLinkedAccount, onRemoveLinkedAccount}: LinkedAccountsProps) => {
  return (
    <Card p="6" m="6" boxShadow="lg">
        <Flex direction="column" alignItems="center">
            <Heading mb="4" fontSize="2xl" textAlign="center">Linked Accounts</Heading>
            <HeadedInfoText headline="Yo, fellow degen! Let's talk about linking up those blockchain accounts to your Decentralized Identifier (DID), shall we?">
                <List spacing={3} textAlign={"left"}>
                    <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        <Text display="inline">It's like giving a cryptographic high-five to prove you own those accounts.</Text>
                    </ListItem>
                    <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        <Text display="inline">This magic trick ensures your accounts are as unique as your NFT collection, all tied up to your DID.</Text>
                    </ListItem>
                    <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        <Text display="inline">Remember, a DID can be a social butterfly with many accounts, but each account is a one-DID kind of guy.</Text>
                    </ListItem>
                    <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        <Text display="inline">This monogamous relationship lets apps and services slide into your blockchain DMs to verify your DID without breaking a sweat.</Text>
                    </ListItem>
                    <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        <Text display="inline">Making your journey through the decentralized wild west smoother than a Solana transaction.</Text>
                    </ListItem>
                </List>
            </HeadedInfoText>
        </Flex>
        {error && <Text>Error fetching linked accounts: {error.message}.</Text>}
        {isLoading && <Text>Loading linked accounts...</Text>}
        {linkedAccounts && linkedAccounts.length > 0 ? (
            <Table variant="simple" width="full" mt={4}>
                <Thead>
                    <Tr>
                        <Th fontSize="lg" width="20%">ID</Th>
                        <Th fontSize="lg" width="20%">Account</Th>
                        <Th fontSize="lg" width="20%">Purpose</Th>
                        {hasWriteAccess && <Th fontSize="lg" width="100%" textAlign="right">Remove</Th>}
                    </Tr>
                </Thead>
                <Tbody>
                    {linkedAccounts.map((account, index) => (
                        <Tr key={index}>
                            <Td fontSize="md">{account.id}</Td>
                            <Td fontSize="md"><LongString text={account.account} maxLength={20} /></Td>
                            <Td fontSize="md">{account.purpose}</Td>
                            {hasWriteAccess && <Td fontSize="md" textAlign="right">
                                <RemoveLinkedAccountButton did={did} onRemoveLinkedAccount={onRemoveLinkedAccount} linkedAccountId={account.id} />
                            </Td>}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        ) : (
            <Text color="gray.500" textAlign={"center"}>No accounts linked.</Text>
        )}
        {hasWriteAccess && <Box mt={4}>
          <Flex justifyContent="flex-end">
            <AddLinkedAccountButton did={did} onSuccess={onAddLinkedAccount} />
          </Flex>
        </Box>}
    </Card>
  );
};

export default LinkedAccounts;
