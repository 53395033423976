import { Text, Card, Heading, List, ListItem, Box, VStack, Table, Tbody, Td, Th, Thead, Tr, Flex, ListIcon, Center } from "@chakra-ui/react";

import { useBreakpointValue } from '@chakra-ui/react';
import React from "react";
import InfoText from "./InfoText";
import HeadedInfoText from "./HeadedInfoText";
import { MdCheckCircle } from "react-icons/md";
import AddKeyButton from "./AddKeyButton";
import RevokeKeyButton from "./RevokeKeyButton";
import type { DidKey } from "../hooks/useDidKeys";
import LongString from "./LongString";

interface DidKeyProps {
    did: string;
    hasWriteAccess?: boolean;
    didKeys: readonly DidKey[] | undefined;
    isLoading: boolean;
    error: Error | null;
    onAddKey: () => void;
    onRevokeKey: () => void;
}

const DidKeys: React.FC<DidKeyProps> = ({ did, hasWriteAccess, didKeys, isLoading, error, onAddKey, onRevokeKey }) => {
    return (
        <Card p="6" m="6" boxShadow="lg">
            <Flex direction="column" alignItems="center">
                <Heading mb="4" fontSize="2xl" textAlign="center">DID Keys</Heading>
                <DidInfoText />
            </Flex>
            {isLoading && <Text>Loading keys...</Text>}
            {error && <Text>Error fetching keys: {error instanceof Error ? error.message : String(error)}</Text>}
            {didKeys && didKeys.length > 0 ? (
                <>
                    <VStack spacing={4}>
                        <Table variant="simple" width="full">
                            <Thead>
                                <Tr>
                                    <Th fontSize="lg" width="20%">Key ID</Th>
                                    <Th fontSize="lg" width="20%">Public Key</Th>
                                    <Th fontSize="lg" width="20%">Key Usage</Th>
                                    <Th fontSize="lg" width="20%">Key Type</Th>
                                    {hasWriteAccess && <Th fontSize="lg" width="20%" textAlign="right">Revoke</Th>}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {didKeys.map((key, index) => (
                                    <Tr key={index}>
                                        <Td fontSize="md">{key.id}</Td>
                                        <Td fontSize="md"><LongString text={key.publicKey} maxLength={20} /></Td>
                                        <Td fontSize="md">
                                            {key.keyUsages.map((usage, index) => (
                                                <React.Fragment key={index}>
                                                    {index > 0 && ', '}
                                                    <LongString text={usage} maxLength={20} />
                                                </React.Fragment>
                                            ))}
                                        </Td>
                                        <Td fontSize="md">{key.keyType}</Td>
                                        {hasWriteAccess && <Td fontSize="md" textAlign="right"><RevokeKeyButton did={did} keyId={key.id} onRemoveKey={onRevokeKey} /></Td>}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </VStack>
                    {hasWriteAccess && <AddKeyButton did={did} onAddKey={onAddKey} />}
                </>
            ) : (
                <Text color="gray.500" textAlign={"center"}>No keys found for this DID.</Text>
            )}
        </Card >
    );
};

const DidKeysMobile: React.FC<DidKeyProps> = ({ did, hasWriteAccess, didKeys, isLoading, error, onAddKey, onRevokeKey }) => {
    return (
        <Card p="4" m="4" boxShadow="sm" textAlign="center">
            <Flex direction="column" alignItems="center">
                <Heading mb="4" fontSize="2xl" textAlign="center">DID Keys</Heading>
                <DidInfoText />
            </Flex>
            {isLoading && <Text textAlign="center">Loading keys...</Text>}
            {error && <Text textAlign="center">Error fetching keys: {error instanceof Error ? error.message : String(error)}</Text>}
            {didKeys && didKeys.length > 0 ? (
                <VStack spacing={4}>
                    {didKeys.map((key, index) => (
                        <Box key={index} p="4" borderWidth="1px" borderRadius="lg" width="full">
                            <VStack align="stretch" spacing={1}>
                                <Text fontSize="md" textAlign="center">
                                    <b>Key ID:</b> {key.id}
                                </Text>
                                <Text fontSize="md" textAlign="center">
                                    <b>Public Key:</b> <Center><LongString text={key.publicKey} maxLength={20} /></Center>
                                </Text>
                                <Text fontSize="md" textAlign="center">
                                    <b>Key Usage:</b> {key.keyUsages.map((usage, uIndex) => (
                                        <React.Fragment key={uIndex}>
                                            
                                            <Center><LongString text={usage} maxLength={20} /></Center>
                                        </React.Fragment>
                                    ))}
                                </Text>
                                <Text fontSize="md" textAlign="center">
                                    <b>Key Type:</b> {key.keyType}
                                </Text>
                                <Text fontSize="md" textAlign="center">
                                    <b>Is Sudo:</b> {key.sudo ? "Yes" : "No"}
                                </Text>
                            </VStack>
                            {hasWriteAccess && <Box textAlign="center" mt="2"><RevokeKeyButton did={did} keyId={key.id} onRemoveKey={onRevokeKey} /></Box>}
                        </Box>
                    ))}
                </VStack>
            ) : (
                <Text color="gray.500" textAlign="center">No keys found for this DID.</Text>
            )}
            {hasWriteAccess && <Box mt={4} textAlign="center"><AddKeyButton did={did} onAddKey={onAddKey} /></Box>}
        </Card>
    );
};

const DidInfoText: React.FC = () => {
    return (
        <HeadedInfoText headline="Exploring the Power of DID Keys">
            <List spacing={3} textAlign={"left"}>
                <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <Text display="inline">Let's dive deeper into <b>DID Keys</b> - your golden ticket to managing your digital identity with precision.</Text>
                </ListItem>
                <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <Text display="inline">These keys aren't just your average access pass; they're the <b>multi-tool</b> in your digital identity toolkit.</Text>
                </ListItem>
                <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <Text display="inline">Each key can be tailored for specific purposes, allowing you to <b>fine-tune</b> exactly which key does what within your DID ecosystem.</Text>
                </ListItem>
                <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <Text display="inline">Whether it's updating your DID keys or commanding your Rareships NFT fleet, the <b>control is in your hands</b>.</Text>
                </ListItem>
                <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <Text display="inline">Enter the realm of <b>sudo accounts</b> - the original creators of your DID, equipped with the power to do just about anything to your DID.</Text>
                </ListItem>
                <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <Text display="inline">Think of them as the <b>master key holders</b>.</Text>
                </ListItem>
                <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <Text display="inline">While having such power is convenient, it's also a <b>double-edged sword</b>.</Text>
                </ListItem>
                <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <Text display="inline">Handle with care, as these sudo accounts hold the keys to your digital kingdom.</Text>
                </ListItem>
                <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <Text display="inline">Keep your DID keys and sudo privileges under lock and key, and you'll <b>reign supreme</b> over your digital domain, no cap.</Text>
                </ListItem>
            </List>
        </HeadedInfoText>
    );
};


const DidKeysResponsive: React.FC<DidKeyProps> = (props) => {
    const isLargeScreen = useBreakpointValue({ base: false, lg: true });

    return isLargeScreen ? <DidKeys {...props} /> : <DidKeysMobile {...props} />;
};


export default DidKeysResponsive;