import { Card, Flex, Heading, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import React from 'react';
import RegisterDgenNameButton from './RegisterDgenNameButton';
import UnregisterDgenNameButton from './UnregisterDgenNameButton';
import InfoText from './InfoText';
import HeadedInfoText from './HeadedInfoText';
import { MdCheckCircle } from 'react-icons/md';

interface DgenNameProps {
    did: string;
    name: string | undefined;
    isLoading: boolean;
    error: Error | null;
    hasWriteAccess: boolean;
    onUnregister: () => void;
    onRegister: () => void;
}

const DgenName: React.FC<DgenNameProps> = ({ did, hasWriteAccess, name, isLoading, error, onUnregister, onRegister }) => {
    return (
        <Card p="6" m="6" boxShadow="lg">
            <Flex direction={"column"} alignItems={"center"}>
                <Heading mb="4" fontSize="2xl" textAlign={"center"}>Dgen Name</Heading>
                <HeadedInfoText headline="Yo, Crypto Fam! Ever Wondered What a Dgen Name Is?">
                    <List spacing={3} textAlign={"left"}>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            <Text display="inline"><b>Personalize Your DID:</b> Assigning a Dgen Name to your DID is like branding your digital identity with a unique tattoo.</Text>
                        </ListItem>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            <Text display="inline"><b>Memorable and Cool:</b> Transforms your complex DID into something as catchy as your favorite gamer tag.</Text>
                        </ListItem>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            <Text display="inline"><b>Enhanced Recognition:</b> Makes your digital presence known and easily recognizable across the metaverse.</Text>
                        </ListItem>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            <Text display="inline"><b>Ownership:</b> Unlike ENS where you rent your name, a Dgen Name means you own it for life—no more rental issues or renewal concerns.</Text>
                        </ListItem>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            <Text display="inline"><b>Permanent Solution:</b> Once claimed, your Dgen Name is yours forever, ensuring long-term consistency for your digital identity.</Text>
                        </ListItem>
                    </List>
                </HeadedInfoText>
                {isLoading && <Text textAlign={"center"}>Loading name...</Text>}
                {error && <Text textAlign={"center"}>Failed to load name ({error.message}).</Text>}
                {name ? <Heading mt="4" as="h3" size="lg" textAlign={"center"}>{name}</Heading> : <Text color="gray.500" mb={2} textAlign={"center"}>No name registered for this DID.</Text>}
                <Flex justifyContent="flex-end" width="100%">
                    {name && hasWriteAccess && <UnregisterDgenNameButton did={did} onUnregister={onUnregister} name={name} />}
                    {!name && hasWriteAccess && <RegisterDgenNameButton did={did} onRegisterSuccess={onRegister} />}
                </Flex>
            </Flex>

        </Card>
    );
};




export default DgenName;
