import * as hardhat from './hardhat';
import * as sepoliaBase from './sepolia-base';

const defaultNetwork = sepoliaBase;

export const DidKeyRegistryAddress = defaultNetwork.DidKeyRegistryAddress;
export const DgenTokenAddress = defaultNetwork.DgenTokenAddress;
export const DidServiceAccountRegistryAddress = defaultNetwork.DidServiceAccountRegistryAddress;
export const DidNameRegistryAddress = defaultNetwork.DidNameRegistryAddress;
export const DidAccountLinkRegistryAddress = defaultNetwork.DidAccountLinkRegistryAddress;
export const FaucetAddress = defaultNetwork.FaucetAddress;

export {
    hardhat,
    sepoliaBase
}
