import { Button as ChakraButton } from '@chakra-ui/react';
import { FC } from 'react';

interface ButtonProps {
    danger?: boolean;
    children: React.ReactNode;
    [x: string]: any; // Allows for arbitrary properties for styling
}

const Button: FC<ButtonProps> = ({danger, children, ...rest}) => {
    let colorScheme = 'teal';
    if (danger) {
        colorScheme = 'red';
    }
    return (
        <ChakraButton colorScheme={colorScheme} minWidth={'5vh'} {...rest}>
            {children}
        </ChakraButton>
    )
}

export default Button;