import React from 'react';
import { Box, Card, Flex, Heading, List, ListIcon, ListItem, Stat, StatHelpText, StatLabel, StatNumber, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import AddServiceAccountButton from './AddServiceAccountButton';
import RemoveServiceAccountButton from './RemoveServiceAccountButton';
import InfoText from './InfoText';
import { MdCheckCircle } from 'react-icons/md';
import HeadedInfoText from './HeadedInfoText';

interface ServiceAccountsProps {
    did: string;
    hasWriteAccess?: boolean;
    serviceAccounts: readonly ServiceAccount[] | undefined;
    isLoading: boolean;
    error: Error | null;
    onAddServiceAccount: () => void;
    onRemoveServiceAccount: () => void;
}

interface ServiceAccount { 
    id: string; 
    types: readonly string[]; 
    endpoints: readonly string[]; 
}

const ServiceAccounts: React.FC<ServiceAccountsProps> = ({ did, hasWriteAccess, serviceAccounts, isLoading, error, onAddServiceAccount, onRemoveServiceAccount }) => {
    return (
        <Card p="6" m="6" boxShadow="lg">
            <Flex direction="column" alignItems="center">
                <Heading mb="4" fontSize="2xl" textAlign="center">Service Accounts</Heading>
                <HeadedInfoText headline="Explore the World of Service Accounts">
                    <List spacing={3} textAlign={"left"}>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            <Text display="inline">Dive into the world of Service Accounts, your DID's secret sauce for flexing your digital identity across the blockchain universe.</Text>
                        </ListItem>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            <Text display="inline">Think of these as your personal digital minions, ready to serve up your deets on a silver platter.</Text>
                        </ListItem>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            <Text display="inline">Want to show off your crypto cred or link out to your meme-tastic Twitter feed? Service Accounts got you.</Text>
                        </ListItem>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            <Text display="inline">They're like your backstage pass to the web's VIP area, letting you drop service endpoints faster than a hot NFT drop.</Text>
                        </ListItem>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            <Text display="inline">Whether it's your ultra-secure IRC hangout or that sneaky onion address, these accounts make sure your digital handshake is as unique as your portfolio.</Text>
                        </ListItem>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            <Text display="inline">Gear up to turbocharge your DID with Service Accounts and make every click in the cryptoverse count.</Text>
                        </ListItem>
                    </List>
                </HeadedInfoText>
            </Flex>
            {isLoading && <Text>Loading service accounts...</Text>}
            {error && <Text>Failed to load service accounts: {error instanceof Error ? error.message : String(error)}.</Text>}
            {serviceAccounts && serviceAccounts.length > 0 ? (
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th fontSize="lg" width="20%">ID</Th>
                            <Th fontSize="lg" width="20%">Types</Th>
                            <Th fontSize="lg" width="20%">Endpoints</Th>
                            {hasWriteAccess && <Th fontSize="lg" width="100%" textAlign="right">Remove</Th>}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {serviceAccounts.map((account, index) => (
                            <Tr key={index}>
                                <Td fontSize="md">{account.id}</Td>
                                <Td fontSize="md">{account.types.join(', ')}</Td>
                                <Td fontSize="md">{account.endpoints.join(', ')}</Td>
                                {hasWriteAccess && <Td fontSize="md" textAlign="right">
                                    <RemoveServiceAccountButton did={did} serviceAccountId={account.id} onRemoveServiceAccount={onRemoveServiceAccount} />
                                </Td>}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            ) : (
                <Text color="gray.500" textAlign={"center"}>No service accounts found for this DID.</Text>
            )}
            
            { hasWriteAccess && <AddServiceAccountButton did={did} onAddServiceAccount={onAddServiceAccount} key={serviceAccounts?.length} />}
        </Card>
    );
};

export default ServiceAccounts;
